<template>
  <div class="AlarmWechat fadeIn">
    <div v-if="loding">
      <img src="../assets/imgproject/loding.png" alt="" />
      <div class="alarmText">绑定中...</div>
    </div>
    <div v-else-if="subscribe == 0">
      <img
        :src="ossUrl + 'wechatimg/wechatImg.jpg'"
        style="width: 50%"
        alt=""
      />
      <div class="alarmText">请长按关注公众号后重新扫码！</div>
    </div>
    <div v-else-if="bind">
      <img src="../assets/imgproject/success.png" alt="" />
      <div class="alarmText">绑定成功！</div>
    </div>
    <div v-else>
      <img src="../assets/imgproject/error.png" alt="" />
      <div class="alarmText">绑定失败！</div>
      <div class="btn" @click="getCode">点击重试</div>
    </div>
  </div>
</template>

<script>
import { APP_ID, ossUrl } from "@/util/global.js";
let that;
export default {
  data() {
    return {
      appId: this.$route.query.appId,
      code: "",
      subscribe: 0,
      bind: false,
      loding: true,
      ossUrl: ossUrl,
    };
  },
  methods: {
    // 获取页面code
    getCode: function () {
      let code = that.getUrlKey("code"); //获取url参数code
      if (code) {
        that.code = code;
        that.getOpenId(that.code);
      } else {
        that.getCodeApi();
      }
    },
    // 发送微信请求获取带code的页面
    getCodeApi: function (state) {
      //获取code
      //   let urlNow = encodeURIComponent(window.location.href);
      let urlNow = encodeURIComponent(window.location.href);
      //   console.log(urlNow);
      let scope = "snsapi_userinfo"; //snsapi_userinfo   //静默授权 用户无感知
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=#wechat_redirect`;
      window.location.replace(url);
    },
    getUrlKey: function (name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [",", ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    getOpenId: function (code) {
      let data = {
        code,
        userId: that.$route.query.userId * 1,
      };
      this.$api.home
        .bindWeCode(data)
        .then((response) => {
          if (response.code == 200) {
            that.subscribe = response.data.subscribe;
            that.loding = false;
            if (response.data.subscribe == 1) {
              that.loding = true;
              let from = {
                wechatOpenId: response.data.openId,
                wechatImg: response.data.wImg,
                appId: this.appId,
                wechatName: response.data.wechatName,
                userId: that.$route.query.userId * 1,
                contactsId: that.$route.query.contactsId * 1,
              };
              that.bindWeUser(from);
            }
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: JSON.stringify(err),
          });
        });
    },
    bindWeUser(form) {
      that.$api.me.bindWeUser(form).then((res) => {
        that.loding = false;
        if (res.code == 200) {
          that.bind = true;
        }
      });
    },
  },
  mounted() {
    that = this;
    that.getCode();
  },
};
</script>

<style scoped>
.AlarmWechat {
  width: 100%;
  height: 100%;
  background: rgb(82, 108, 135);
  box-sizing: border-box;
  padding: 20px 0;
}
.AlarmWechat > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.alarmText {
  width: 100%;
  line-height: 50px;
  font-size: 25px;
  text-align: center;
  color: #fff;
}
.AlarmWechat img {
  width: 30%;
}
.btn {
  width: 80%;
  line-height: 50px;
  font-size: 25px;
  text-align: center;
  color: #fff;
  border-radius: 12px;
  background: rgb(120, 192, 110);
  margin-top: 15px;
}
</style>
